<template>
  <v-container>
    <div v-if="!positive || !negative">
      loader...
    </div>
    <v-row v-else>
      <v-col>
        <v-textarea solo v-model="text" counter label="Artikel" />
        <v-btn @click="calcWordCount" color="primary">Beregn antal ord</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Positive ord</v-card-title>
          <v-card-text>
            <div>Unikke: {{ positiveCounts.length }}</div>
            <div>Total: {{ totalPositiveCount }}</div>
            <br />
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Ord</th>
                    <th class="text-left">Antal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="word in sortedPositiveCounts" :key="word.word">
                    <td>{{ word.word }}</td>
                    <td>{{ word.count }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Negative ord</v-card-title>
          <v-card-text>
            <div>Unikke: {{ negativeCounts.length }}</div>
            <div>Total: {{ totalNegativeCount }}</div>
            <br />
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Ord</th>
                      <th class="text-left">Antal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="word in sortedNegativeCounts" :key="word.word">
                      <td>{{ word.word }}</td>
                      <td>{{ word.count }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      positive: null,
      negative: null,
      text: null,
      positiveCounts: [],
      negativeCounts: []
    }
  },
  computed: {
    sortedPositiveCounts () {
      const counts = [...this.positiveCounts]
      return counts.sort((a, b) => a.count > b.count ? -1 : 1)
    },
    sortedNegativeCounts () {
      const counts = [...this.negativeCounts]
      return counts.sort((a, b) => a.count > b.count ? -1 : 1)
    },
    totalPositiveCount () {
      if (!this.positiveCounts) return 0
      return this.positiveCounts.reduce((sum, word) => sum += word.count, 0)
    },
    totalNegativeCount () {
      if (!this.negativeCounts) return 0
      return this.negativeCounts.reduce((sum, word) => sum += word.count, 0)
    }
  },
  methods: {
    calcWordCount () {
      const positiveMap = { ...this.positive }
      const negativeMap = { ...this.negative }
      this.positiveCounts = []
      this.negativeCounts = []
      this.text.split(' ').forEach(word => {
        const upperCaseWord = word.toUpperCase()
        if (typeof positiveMap[upperCaseWord] !== undefined) positiveMap[upperCaseWord]++
        if (typeof negativeMap[upperCaseWord] !== undefined) negativeMap[upperCaseWord]++
      })
      Object.keys(positiveMap).forEach(word => {
        const count = positiveMap[word]
        if (count > 0) this.positiveCounts.push({ word, count })
      })
      Object.keys(negativeMap).forEach(word => {
        const count = negativeMap[word]
        if (count > 0) this.negativeCounts.push({ word, count })
      })
    }
  },
  created () {
    this.positive = require('@/assets/positive.json')
    this.negative = require('@/assets/negative.json')
  }
}
</script>
